<template>
  <base-form-bottom
    ref="form-bottom"
    path="home-orders-service-list"
  >
    <div
      class="d-flex"
      style="gap: 8px"
    >

      <b-button
        v-for="({ variant, style, action, title, icon, formSubmitAction }, idx) in isOrderInInvoice ? formBottomActionsInvoice : formBottomActions"
        :key="idx"
        :variant="variant"
        class="p-0 d-flex justify-content-center align-items-center"
        :style="style"
        :disabled="disableByStateAndStatus(action)"
        @click="buttonEvent(formSubmitAction, action)"
      >
        <!--        :disabled="loadingFor.isLoading || disableByStateAndStatus(action)"-->
        <Printer>
          <div
            id="print"
            hidden
          >
            <ServiceOrderPrintTemplate />
          </div>
        </Printer>
        <b-spinner
          v-if="loadingFor.isLoading && loadingFor.action === action"
          small
          variant="secondary"
        />
        <span
          v-else
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon
            v-if="icon"
            :icon="icon"
          />
          <span>
            {{ $t(title) }}
          </span>
        </span>
      </b-button>
    </div>
  </base-form-bottom>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { mapGetters } from 'vuex'
import jsonToFormData from '@/libs/jsonToFormData'
import BaseFormBottom from '@/views/main/orders/components/use-as-global/BaseFormBottom.vue'
import ServiceOrderPrintTemplate
from '@/views/main/orders/view/service/components/common/print/ServiceOrderPrintTemplate.vue'
import Printer from '@/views/components/Printer/Printer.vue'
import config from './config'
import mainConfig from '../../../config'

export default {
  name: 'FormBottom',
  components: {
    Printer,
    ServiceOrderPrintTemplate,
    BSpinner,
    BButton,
    BaseFormBottom,
  },
  props: {
    formRefs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
      },
    }
  },
  computed: {
    ...mapGetters('service', ['getTotalDataOfForms']),
    isOrderInInvoice() {
      return this.getTotalDataOfForms.order.state === 2 && (this.getTotalDataOfForms.order.status === 1 || this.getTotalDataOfForms.order.status === 4)
    },
  },
  methods: {
    buttonEvent(formSubmitAction, action) {
      if (action === this.FORM_BUTTON_PRINT.action) {
        this.printRFQForSupplierTemplate()
        return
      }
      this.$refs['form-bottom'][formSubmitAction] = this[formSubmitAction]
      this.$refs['form-bottom'][formSubmitAction](action)
    },
    // eslint-disable-next-line consistent-return
    disableByStateAndStatus(action) {
      if (action === this.FORM_BUTTON_BILL_SERVICE.action && this.getTotalDataOfForms.order.state === 2 && this.getTotalDataOfForms.order.status === 4) {
        return true
      }
      return false
    },
    redirectTo(id, action, damagedId) {
      if (action === this.FORM_BUTTON_ACCEPT.action) {
        this.$router.push({ name: 'invoice', params: { id } })
      } else if (action === this.FORM_BUTTON_VOID.action) {
        this.$router.push({ name: 'request-service-quote-view', params: { id } })
      } else if (action === this.FORM_BUTTON_SELL_ASSET.action) {
        this.$router.push({ name: 'hold-quote-information', params: { id } })
      } else if (action === this.FORM_BUTTON_BILL_SERVICE.action) {
        this.$router.push({ name: 'hold-quote-information', params: { id } })
        // window.location.reload()
      } else {
        this.$router.push({ name: 'home-orders-service-list' })
      }
    },
    async submit(action) {
      const { formRefs: { requestServiceQuoteForm }, getTotalDataOfForms } = this
      const {
        servicesPerformed, otherCharges, notes: { received_comments }, order: { id }, attachments,
      } = getTotalDataOfForms

      const success = await requestServiceQuoteForm.validate()
      if (!success) {
        scrollToError(this, requestServiceQuoteForm)
        return
      }

      this.loadingFor = {
        isLoading: true,
        action,
      }

      const convertedMappedDataToFormData = jsonToFormData({
        id,
        attachments: attachments.filter(attachment => !attachment.id),
        received_comments,
        other_charges: otherCharges.map(({
          name, unit, price, discount, tax,
        }) => ({
          name, unit, subtotal: (((+unit * +price) - +discount) + +tax).toFixed(2), discount: discount || 0, price, tax,
        })),
        service_performed: servicesPerformed.map(({
          name, unit, price, discount, tax,
        }) => ({
          name, unit, subtotal: (((+unit * +price) - +discount) + +tax).toFixed(2), discount: discount || 0, price, tax,
        })),
      })

      try {
        if (action === this.FORM_BUTTON_VOID.action) {
          const convertedMappedDataToFormId = jsonToFormData({ id })
          await this.sendNotification(this, convertedMappedDataToFormId, `${this.MODULE_NAME}/send-out-void`).then(({ data }) => {
            const { id, inventory_item_damaged_id } = data.data[0]
            this.redirectTo(id, action, inventory_item_damaged_id)
          }).catch(err => {
            this.errorNotification(this, err)
          }).finally(() => {
            this.$emit('clear')
          })
        } else if (action === this.FORM_BUTTON_SELL_ASSET.action) {
          await this.sendNotification(this, { id }, `${this.MODULE_NAME}/sellAsset`).then(({ data }) => {
            this.redirectTo(data?.data?.id, action)
          }).catch(err => {
            this.errorNotification(this, err)
          }).finally(() => {
            this.$emit('clear')
          })
        } else {
          const actionName = action === this.FORM_BUTTON_BILL_SERVICE.action ? 'invoice-service' : 'send-out-accept'
          await this.sendNotification(this, convertedMappedDataToFormData, `${this.MODULE_NAME}/${actionName}`).then(res => {
            const { data } = res
            this.redirectTo(data.data.id, action)
          }).catch(err => {
            this.errorNotification(this, err)
          }).finally(() => {
            this.$emit('clear')
          })
        }
      } catch (err) {
        this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data ? err.response?.data : err.response?.message)
      } finally {
        this.loadingFor = {
          isLoading: false,
          action: null,
        }
      }
    },
    printRFQForSupplierTemplate() {
      this.$htmlToPaper('print', {
        autoClose: false,
      })
    },
  },
  setup() {
    const {
      notesFields,
      FORM_BUTTON_VOID,
      FORM_BUTTON_PRINT,
      formBottomActions,
      FORM_BUTTON_ACCEPT,
      FORM_BUTTON_SELL_ASSET,
      formBottomActionsInvoice,
      FORM_BUTTON_BILL_SERVICE,
    } = config()

    const {
      STATE_SO,
      STATE_RFQ,
      STATUS_VOID,
      MODULE_NAME,
      STATUS_READY,
      STATE_INVOICE,
      STATUS_WARNING,
      STATUS_NO_ACTION,
      STATUS_ATTENTION,
      STATUS_IN_PROGRESS,
    } = mainConfig()

    return {
      STATE_SO,
      STATE_RFQ,
      notesFields,
      STATUS_VOID,
      MODULE_NAME,
      STATUS_READY,
      STATE_INVOICE,
      STATUS_WARNING,
      FORM_BUTTON_VOID,
      STATUS_NO_ACTION,
      STATUS_ATTENTION,
      FORM_BUTTON_PRINT,
      formBottomActions,
      STATUS_IN_PROGRESS,
      FORM_BUTTON_ACCEPT,
      FORM_BUTTON_SELL_ASSET,
      FORM_BUTTON_BILL_SERVICE,
      formBottomActionsInvoice,
    }
  },
}
</script>
